import React from 'react'
import PropTypes from 'prop-types'
import HomeHeroBanner from './HomeHeroBanner'
import KeyBenefitsSection from 'src/components/pages/Home/KeyBenefitsSection'
import OutageBanner from './OutageBanner'
import HowItWorks from 'src/components/pages/Home/HowItWorks'
import TrustPilotReviews from 'src/components/shared/BrandPages/TrustPilotReviews'
import ClientStories from 'src/components/pages/Home/ClientStories'
import Faqs from 'src/components/pages/Home/Faqs'
import HeroBannerFooter from 'src/components/pages/Home/HeroBannerFooter/variation'
import IconStrip from './HomeHeroBanner/IconStrip'
import Awards from 'src/components/shared/Awards'

const HomePage = (props) => {
  const {
    data,
    outageBanner,
    homeVariation,
    isDefaultLang = false,
    reviews,
  } = props

  const { heroBanner, heroBannerFooter, sliderSettings, clientStories } = data
  const heroContent = { ...heroBanner.content, number: '833-418-3210' }

  return (
    <div className="w-content mx-auto">
      <OutageBanner {...outageBanner} />

      {/* Hero section */}
      <HomeHeroBanner
        title={heroBanner.title}
        text={heroBanner.text}
        name={heroBanner.name}
        content={heroContent}
        isDefaultLang={isDefaultLang}
        nameSuperscript="⁴"
      />

      {/* statics section */}
      <IconStrip iconStrip={heroBanner.iconStrip} />
      <Awards
        className="px-4 pt-12 lg:py-10"
        desktopTitleClassname="border-b border-gray-270 mb-4"
        isFooter={false}
      />

      {/* Take control section */}
      <KeyBenefitsSection variation={homeVariation} />

      {/* How it works section */}
      <HowItWorks variation={homeVariation} />

      {/* Trustpilot */}
      <TrustPilotReviews
        reviews={reviews}
        slickSliderSettings={sliderSettings}
        trustBoxClassName="bg-blue-base-white lg:px-20"
        itemClassname="bg-white"
      />

      {/* Client stories */}
      <div className="flex items-center justify-center">
        <ClientStories
          clientStories={clientStories}
          variation={homeVariation}
        />
      </div>

      {/* FAQs */}
      <div className="flex items-center justify-center bg-[#EFF5FF] lg:bg-[#F5F9FF]">
        <Faqs />
      </div>

      <HeroBannerFooter
        content={heroContent}
        headerInfo={heroBannerFooter.header}
        isDefaultLang={isDefaultLang}
        shortView={false}
      />
    </div>
  )
}

HomePage.defaultProps = {
  isDefaultLang: true,
}

HomePage.propTypes = {
  data: PropTypes.object.isRequired,
  isDefaultLang: PropTypes.bool,
}

export default HomePage
